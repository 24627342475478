
main {
	margin: 0 auto;
	max-width: 90%;
  padding: 50px 30px;
}
:root {
  --primary: #0958ad;
  --secondary: #a2a1eb;
  --tertiary: #ff3d2e;
  /* --light: #ffc107; */
  --light: #ffffff;
  --dark: #291057;
  --darker: #111111;
  --error: rgb(228, 46, 1);
  --spacing-one: calc(1rem * 0.5);
  --spacing-two: calc(1rem);
  --spacing-five: calc(2.5rem);

}

* {
  box-sizing: border-box;
}

html,
body {
  min-height: 100vh;
}

body {
  background-image: url("./assets/cover/background-copy.jpg"); 
  background-size: cover;
  background-position: center;
  position: relative;
  margin: 0;
  padding: 0;
  font-weight: 600;
  line-height: 1.5;
  font-size: 18px;
  letter-spacing: .1em;
  /* background: var(--dark); */
  color: var(--light);
  font-family: 'Kdam Thmor Pro', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Kdam Thmor Pro', sans-serif;
  font-weight: 600;
  margin: 5px 0;
  /* line-height: 1.25; */
}

h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  font-size: 1.1rem;
  margin-top: 0;
}

a {
	border-radius: 5px;
	color: var(--light);
  text-decoration: none;
	font-weight: 600;
	padding: 2px 4px;
	margin-left: -2px;
	margin-right: -2px;
}

a:hover {
  text-decoration: none;
  color: var(--secondary);
}

header {
  background: var(--dark);
}

header a {
  color: var(--light);
}

button {
  font-size: 1rem;
  text-decoration: none;
  padding: 0.25rem 1rem;
  border-radius: .5rem;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0 1px;
  background-color: var(--secondary);
  font-family: 'Kdam Thmor Pro', sans-serif;
}

button:hover {
  opacity: .8;
}

button[disabled] {
  cursor: default;
  opacity: .8;
}

img {
  max-width: 100%;
}

span[role="img"] {
  cursor: default;
}

form {
  width: 80%;
}

input {
  font-size: 1rem;
  padding: 0.25rem;
  border: 1px solid var(--dark);
  outline: none;
  border-radius: 3px;
  transition: box-shadow 0.2s;
}

input:focus {
  border: 1px solid var(--secondary);
  box-shadow: 0 0 .25rem rgba(0, 0, 0, .5);
}

li {
  list-style: none;
}

nav a:hover {
  text-decoration: none;
}

nav  li {
  cursor: pointer;
}

textarea {
	height: 200px;
	border: 3px solid #cccccc;
	padding: 5px;
}

.container {
  width: 85%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
}

.space-between {
  justify-content: space-between;
}

.space-evenly {
  justify-content: space-evenly;
}

.space-around {
  justify-content: space-around;
}


.flex-end {
  justify-content: flex-end;
}

.mx-1 {
  margin-right: var(--spacing-one);
  margin-left: var(--spacing-one);
}

.mx-2 {
  margin-right: var(--spacing-two);
  margin-left: var(--spacing-two);
}

.my-1 {
  margin-top: var(--spacing-one);
  margin-bottom: var(--spacing-one);
}

.my-2 {
  margin-top: var(--spacing-two);
  margin-bottom: var(--spacing-two);
}

.my-5 {
  margin-top: var(--spacing-five);
  margin-bottom: var(--spacing-five);
}

.px-1 {
  padding-right: var(--spacing-one);
  padding-left: var(--spacing-one);
}

.px-2 {
  padding-right: var(--spacing-two);
  padding-left: var(--spacing-two);
}

.py-1 {
  padding-top: var(--spacing-one);
  padding-bottom: var(--spacing-one);
}

.py-2 {
  padding-top: var(--spacing-two);
  padding-bottom: var(--spacing-two);
}

.py-12 {
  padding-top: 12px;
}

.card {
  width: 25%;
  text-align: center;
}

.modalBackdrop {
	background: rgba(0, 0, 0, .65);
	bottom: 0;
	left: 0;
	overflow: auto;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1;
}

.modalContainer {
	background: var(--darker);
	border-radius: 5px;
	max-width: 100%;
	margin: 50px auto;
	padding: 15px;
	width: 960px;
}

.modalTitle {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 25px;
}

button {
	background: var(--primary);
	border: 0;
	border-radius: 5px;
	color: #fff;
	cursor: pointer;
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 30px;
	padding: 10px 15px;	
}

.resume-title, .language-list, .database-list, .tool-list, .framework-list, .icon-p{
  text-align: center;
}

.language-list, .database-list, .tool-list, .framework-list {
  padding: auto, 0px;
}

.resume-p{
  text-align: center;
}

.resume-link, .github-link, .deployed-url-link {
  font-size: 20px;
  background-color: var(--dark);
  padding: 10px;
  border-radius: 15px;
  text-decoration: none;
}


.github-p{
  text-align: center;
}

.resume-link:hover, .github-link:hover, .deployed-url-link:hover {
  background-color: var(--light);
  color: var(--primary);
  text-decoration: none;
}

.resume-link i {
  font-size: 20px;
}

.resume-container{
  margin-bottom: 50px;
}

.skills-container{
  /* background: var(--dark); */
  box-shadow: 3px 3px 3px rgb(0 0 0 / 50%), -3px -3px 3px rgb(0 0 0 / 50%);
  /* box-shadow: initial; */
  display: inline-block;
  margin-bottom: 50px;
  padding: 20px;
}

.resume-skills{
  text-align: center;
}

.about-img{
  width: 25%;
}

.icon {
  font-size: 50px;
  padding-left: 10px;
}


.about-p{
  width: 70%;
}

.navActive {
  color: var(--secondary);
}

footer {
  background-color: var(--dark);
  position: absolute;
  left:0;
  bottom:0;
  right:0; 
}

label {
  text-align: left;
  display: block;
  margin-bottom: 5px;
}

input, textarea{
  width: 100%;
  margin-bottom: 10px;
  border-radius: 5px;
}

textarea{
  resize: vertical;
}

.contact-title, .contact-container {
  text-align: center;
}

#contact-form{
  display: inline-block;
}

.contact-info{
  text-align: center;
}

.contact{
  font-size: 1.75rem;
}

.portfolio-title{
  text-align: center;
  margin-bottom: 30px;
}

.slider{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  padding: 30px 0;
}

.image-description, .image-technologies{
  width: 900px;
  margin-bottom: 25px;
}
/* .slider-border{
  box-shadow: 3px 3px 3px rgb(0 0 0 / 50%), -3px -3px 3px rgb(0 0 0 / 50%);
  background-color: #0958ad;
} */

.img-thumbnail {
  width: 900px;
  height: 540px;
  border-radius: 10px;
  display: block;
  margin-bottom: 30px;
}

.right-arrow{
  position: absolute;
  top: 50%;
  right: -50px;
  font-size: 3rem;
  color: var(--light);
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrow{
  position: absolute;
  top: 50%;
  left: -50px;
  font-size: 3rem;
  color: var(--light);
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.right-arrow:hover, .left-arrow:hover{
  color: var(--secondary);
}

.slide{
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active{
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}

@media screen and (max-width: 1115px) {
  #about{
    text-align: center;
  }

  .about-img{
    display: block;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .about-p{
    width: 100%;
  }

  .icon{
      font-size: 2rem;
  }

  .img-thumbnail {
    width: 700px;
    height: 420px;
    border-radius: 10px;
    display: block;
    margin-bottom: 30px;
  }
  
  .image-description, .image-technologies{
    width: 700px;
  }
}

@media screen and (max-width: 980px) {
  .img-thumbnail {
    width: 500px;
    height: 300px;
    border-radius: 10px;
    display: block;
    margin-bottom: 30px;
  }

  .image-description, .image-technologies{
    width: 500px;
  }
}

@media screen and (max-width: 768px) {
  .about-img{
    width: 75%;
  }

  h1{
    font-size: 2rem;
  }

  h3{
    font-size: 1.5rem;
  }

  .icon, .contact{
    font-size: 1.5rem;
  }

  .img-thumbnail {
    width: 400px;
    height: 240px;
    border-radius: 10px;
    display: block;
    margin-bottom: 30px;
  }

  .image-description, .image-technologies{
    width: 400px;
    font-size: .75rem;
  }
}

@media screen and (max-width: 575px) {
  .about-img{
    width: 100%;
  }

  h3{
    font-size: 1rem;
  }

  .icon, .contact{
    font-size: 1.25rem;
  }

  .resume-link {
    font-size: 14px;
  }

  .py-12 {
    padding-top: 3px;
  }
  /* .contact{
    display: block;
  } */
  .img-thumbnail {
    width: 300px;
    height: 180px;
    border-radius: 10px;
    display: block;
    margin-bottom: 30px;
  }

  .image-description, .image-technologies{
    width: 300px;
    font-size: .75rem;
  }

  .image-title{
    width: 300px;
    text-align: center;
  }

  .right-arrow, .left-arrow{
    font-size: 1.5rem;
  }

  .right-arrow{
    right: -30px
  }

  .left-arrow{
    left: -30px
  }
}

@media screen and (max-width: 450px) {
  .img-thumbnail {
    width: 200px;
    height: 90px;
    border-radius: 10px;
    display: block;
    margin-bottom: 30px;
  }

  .image-description, .image-technologies{
    width: 200px;
    font-size: .75rem;
  }

  .image-title{
    width: 200px;
  }

  .right-arrow, .left-arrow{
    font-size: 1.5rem;
  }

  .right-arrow{
    right: -30px
  }

  .left-arrow{
    left: -30px
  }

  .deployed-url-link, .github-link{
    font-size: .75rem;
  }
}